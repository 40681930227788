<template>
  <app-list-page v-bind="{ remote, props }" />
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      remote: api.user,
      props: [
        {
          type: 'selection',
          width: 50,
          align: 'center'
        },
        {
          label: '手机号(用户名)',
          prop: 'phone',
          width: 200
        },
        {
          label: '姓名',
          prop: 'name',
          width: 200
        },
        {
          label: '部门',
          prop: 'departmentName'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
